import * as React from "react"
import Layout from "../components/layout"
const IndexPage = () => (
  <Layout>
    <div class="overflow-x-hidden bg-gradient-to-b from-yellow-500 ">
      <div class="px-6 py-8">
        <div class="max-w-7xl  container flex justify-between mx-auto radius-md">
          <div class="container overflow-x-hidden  ">
            <div class="text-xl text-center  p-10 justify-center">
              <h1 class="text-xl text-red-800 text-center  p-5 justify-center text-6xl	">
                What we believe
              </h1>
            </div>
            <dl>
              <dd class="text-xl text-black text-center   p-10 justify-center">
                Calvary Chapel has been formed as a fellowship of believers in
                the Lordship of Jesus Christ. Our supreme desire is to know
                Christ and to be conformed into His image by the power of the
                Holy Spirit.
              </dd>
              <dd class="text-xl text-black text-center  p-10">
                WE BELIEVE the Bible to be the only inspired, infallible, and
                authoritative Word of God
              </dd>
              <dd class="text-xl text-black text-center p-10">
                WE BELIEVE there is one God, eternally existent in perfect unity
                as three Persons: Father, Son, and Holy Spirit.
              </dd>{" "}
              <dd class="text-xl text-black text-center p-10">
                WE BELIEVE in the deity of Jesus Christ; in His Virgin Birth; in
                His sinless human life; in His divine miracles; in His vicarious
                and atoning death through His shed blood; in His bodily
                resurrection; in His ascension to the right hand of the Father;
                and in His personal return in power and glory.
              </dd>{" "}
              <dd class="text-xl text-black text-center p-10">
                WE BELIEVE the Holy Spirit was sent by the Father and the Son to
                convict the world of sin, righteousness, and judgment. We
                believe the Holy Spirit regenerates and indwells and seals every
                believer in the Lord Jesus Christ, and that He empowers us to
                live as witnesses for Christ. We believe that all of the gifts
                of the Holy Spirit are available to His church today. (John
                14:16-17, John 16:7-11, Titus 3:5-6, Romans 8:9, 1 Corinthians
                6:19-20, Ephesians 1:13-14, Acts 1:8, Romans 12:6-8, 1
                Corinthians 12-14, 1 Peter 4:10-11)
              </dd>{" "}
              <dd class="text-xl text-black text-center p-10">
                WE BELIEVE that God created human beings, male and female, in
                His own image. He created them sinless, equal in value, dignity,
                and worth. According to His purpose and design, men and women
                are created to fulfil distinct but complementary roles in the
                contexts of marriage, family, and the local church. (Genesis
                1:26-27, Ephesians 5:22-6:4, 1 Timothy 3:1-7)
              </dd>
              <dd class="text-xl text-black text-center p-10">
                WE BELIEVE that, as a result of the Fall, all people are sinners
                by nature and in need of a Saviour, and that salvation is by
                grace alone through faith alone in Jesus Christ alone. We
                believe that all who call on the name of the Lord shall be saved
                eternally. (Romans 3:10, Romans 3:23, Matthew 1:21, John 14:6,
                Ephesians 2:8-9, Romans 10:9, John 10:28)
              </dd>
              <dd class="text-xl text-black text-center p-10">
                WE BELIEVE that for the salvation of lost and sinful humanity,
                regeneration by the Holy Spirit is essential and that repentance
                from sin and acceptance of Jesus Christ as Lord and Saviour is
                the only way to come into a relationship with God. (Romans 8:9,
                14-16)
              </dd>
              <dd class="text-xl text-black text-center p-10">
                WWE BELIEVE there is one church, the body of Christ, consisting
                of men and women from every tribe, tongue, people, and nation.
                We believe that each local congregation is an expression of that
                universal church. (Ephesians 4:4-5, 1 Corinthians 12:13,
                Revelation 5:9, Acts 2:42)
              </dd>
              <dd class="text-xl text-black text-center p-10">
                WE BELIEVE in and expectantly await the glorious, visible,
                personal, premillennial return of the Lord Jesus Christ. The
                blessed hope of His return has vital bearing on the personal
                life, service, and mission of the believer (1 Thessalonians
                4:13–18).
              </dd>{" "}
              <dd class="text-xl text-black text-center p-10">
                WE BELIEVE in the bodily resurrection of both the saved and the
                lost. The lost will be raised to judgment and experience eternal
                wrath in Hell. The saved will be raised to eternal joy in the
                new Heaven and new earth in the manifested presence of God
                (Revelation 20:6, 12-15).
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
